import cog from './cog.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <img src={cog} className="Cog" alt="Cog"/>
        <p>Something, Something, TODO...</p>
        <p>...eventually</p>
    </div>
  );
}

export default App;
